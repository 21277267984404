<template>
  <div>
    <span class="red--text">
      {{ errorMessage }}
    </span>
    <br />
    <v-menu
      ref="menu"
      :close-on-content-click="false"
      class="ma-0 pa-0"
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          prepend-icon="mdi-clock-time-four-outline"
          v-bind="attrs"
          v-on="on"
          depressed
          width="150px"
          outlined
          v-bind:color="hasError"
        >
          {{ timeLabel }}
          <v-icon right>
            {{ icons.clock }}
          </v-icon>
        </v-btn>
      </template>
      <v-time-picker
        :allowed-minutes="allowableMinutes"
        full-width
        v-model="time"
        @click:minute="$refs.menu.save(time)"
        ref="time"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
import { mdiClockOutline } from '@mdi/js'

export default {
  data() {
    return {
      allowableMinutes: [0, 30],
      icons: {
        clock: mdiClockOutline
      },
      time: '00:00'
    }
  },
  props: {
    label: {
      type: String,
      default: 'label'
    },
    errorMessage: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    hasError() {
      return this.errorMessage ? 'error' : ''
    },
    errorMargin() {
      return !this.errorMessage ? 'mt-11' : ''
    },
    timeLabel() {
      if (this.time) {
        this.$emit('input', this.time)
      }
      return this.time ? this.time : this.label
    }
  },
  methods: {
    timeUpdated(val) {
      if (val) {
        this.time = val
        this.$emit('input', this.$refs.time.value)
      }
    },
    initValue() {
      this.time = this.value
    }
  },
  mounted() {
    this.initValue()
  }
}
</script>
