<template>
  <div>
    <v-card outlined>
      <v-card-title>
        Business Hours
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-for="(n, i) in 6" :key="i" class="ml-0">
            <v-col cols="12" sm="12" class="mt-2 ml-0 mb-0">
              <v-chip
                color="primary"
                label
                style="width:100px"
                class="font-weight-bold"
              >
                {{ days[i] }}
              </v-chip>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-simple-table width="100%">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Opening
                      </th>
                      <th class="text-left">
                        Closing
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(val, index) in hours[i]" :key="index">
                      <td>
                        <time-picker
                          :label="val.from ? val.from : 'Opening'"
                          v-model="val.from"
                          :errorMessage="timeErrors[i][index].from"
                        ></time-picker>
                      </td>
                      <td>
                        <time-picker
                          :label="val.to ? val.to : 'Closing'"
                          v-model="val.to"
                          :errorMessage="timeErrors[i][index].to"
                        ></time-picker>
                      </td>
                      <td>
                        <v-btn fab icon x-small @click="removeHour(i, index)">
                          <v-icon>
                            {{ icons.remove }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">
                        <v-btn small block @click="addHour(i)">
                          Add Hours
                          <v-icon>
                            {{ icons.add }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiPlus, mdiClose, mdiMinus } from '@mdi/js'
import TimePicker from '@/components/TimePicker'
import { each } from 'lodash'

export default {
  data() {
    return {
      days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      icons: {
        add: mdiPlus,
        close: mdiClose,
        remove: mdiMinus
      }
    }
  },
  props: {
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hours: {
      type: Array,
      default: () => [[], [], [], [], [], [], []]
    }
  },
  components: {
    TimePicker
  },
  computed: {
    timeErrors() {
      let errs = []
      each(this.hours, (v, d) => {
        errs[d] = []
        each(v, (t, i) => {
          let e = { from: '', to: '' }
          const keyNameFrom = 'venue_hours.' + d + '.' + i + '.from'
          const keyNameTo = 'venue_hours.' + d + '.' + i + '.to'

          if (!t.from) {
            e.from = 'Opening time cannot be blank'
          }
          if (!t.to) {
            e.to = 'Closing time cannot be blank'
          }

          if (keyNameFrom in this.errors) {
            e.from = this.errors[keyNameFrom][0]
          }
          if (keyNameTo in this.errors) {
            e.from = this.errors[keyNameTo][0]
          }

          errs[d].push(e)
        })
      })
      return errs
    }
  },
  methods: {
    addHour(index) {
      if (
        !this.timeErrors[index].length ||
        (this.timeErrors[index][this.timeErrors[index].length - 1] &&
          !this.timeErrors[index][this.timeErrors[index].length - 1].from &&
          this.timeErrors[index][this.timeErrors[index].length - 1] &&
          !this.timeErrors[index][this.timeErrors[index].length - 1].to)
      )
        this.hours[index].push({ from: null, to: null })
      this.timeUpdated()
    },
    removeHour(dayIndex, index) {
      this.hours[dayIndex].splice(index, 1)
      this.timeUpdated()
    },
    timeUpdated() {
      this.$emit('changed', this.hours)
    }
  }
}
</script>
