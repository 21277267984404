<template>
  <div class="media-uploader d-flex" :class="centerClass">
    <media-item
      v-for="file in files"
      :key="file.uid"
      :media="file"
      @onRemove="removeFile"
    />
    <vue-dropzone
      v-show="!isMaxFiles"
      class="dropzone-uploader"
      ref="dropzone"
      :options="dropzoneOptions"
      useCustomSlot
      id="0"
      @vdropzone-removed-file="originalFileRemoved"
      @vdropzone-thumbnail="fileAdded"
      @vdropzone-upload-progress="uploadProgress"
      @vdropzone-error="onError"
      @vdropzone-success="onSuccess"
      @vdropzone-complete="onComplete"
    >
      <div class="media-uploader--content">
        <div class="media-uploader--icon">
          <img src="@/assets/svg/add-photo.svg" alt="Add Photo" />
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { findIndex, find, get, each } from 'lodash'
import MediaItem from './media-item'
import Media from '@/models/Media'

/**
 * Available Events
 * @update:loading : Sync loading value
 * @onFileUploadSuccess : Event fire when a file uploaded successfuly
 * @onFileRemove : Event file when a file has been remove
 */
export default {
  props: {
    icon: {
      type: String,
      default: 'image',
      validator: icon => {
        return ['image', 'folder', 'file'].indexOf(icon) !== -1
      }
    },
    error: {
      type: Boolean,
      default: false
    },
    /**
     * Limit the number of files to upload
     */
    maxFiles: {
      type: [Number, null],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Preview old media
     */
    storedFiles: {
      type: Array,
      default: () => []
    },
    /**
     * It Will auto delete the media on the backend
     * when click btn has click
     * Pass false to handle it manually
     */
    autoBackendDelete: {
      type: Boolean,
      default: false
    }
  },

  components: {
    vueDropzone: vue2Dropzone,
    MediaItem
  },

  data() {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/media',
        maxFiles: this.maxFiles,
        headers: {
          Authorization: 'Bearer ' + this.$store.state.auth.token.access_token
        }
      },
      files: []
    }
  },

  computed: {
    // center the upload image icon if no files
    centerClass() {
      return {
        'justify-center align-center': !this.hasFiles,
        'with-error': this.error
      }
    },
    // check if theres already files added
    hasFiles() {
      return this.files.length > 0
    },
    isMaxFiles() {
      return this.maxFiles && this.files.length >= this.maxFiles
    },
    // get what add icon to show
    addIcon() {
      if (this.icon === 'image') {
        return '@/assets/svg/add-photo.svg'
      } else {
        return '@/assets/svg/add-folder.svg'
      }
    }
  },

  methods: {
    // Get auth access token
    accessToken() {
      return get(this.$auth, "$storage._state['_token.local']")
    },

    // handled event when user added a file / files
    fileAdded(originalFile, dataURL) {
      this.$emit('update:loading', true)
      this.files.push({
        id: originalFile.upload.uuid,
        uid: originalFile.upload.uuid,
        name: originalFile.name,
        file_name: originalFile.name,
        mime_type: originalFile.type,
        thumb_url: dataURL,
        url: dataURL,
        originalFile: originalFile,
        dropzone: {
          accepted: originalFile.accepted,
          processing: originalFile.processing,
          progress: 0,
          upload: originalFile.upload,
          error: false
        }
      })
    },

    // Gets called periodically whenever the file upload progress changes.
    uploadProgress(originalFile, progress) {
      this.$emit('update:loading', true)
      const media = find(this.files, { uid: originalFile.upload.uuid })
      if (media) {
        media.dropzone.upload = originalFile.upload
        // when progress is 100, assign 80 only
        // will mark it 100 on upload success
        media.dropzone.progress = progress >= 80 ? 80 : progress
      }
    },

    // File upload success
    onSuccess(originalFile, response) {
      const media = find(this.files, { uid: originalFile.upload.uuid })
      if (media) {
        // mark progress to 100
        media.dropzone.progress = 100
        // Emit the media object from response
        media.id = response.data.id
        this.$emit('onFileUploadSuccess', response.data)
      }
    },

    onComplete() {
      this.$emit('update:loading', false)
    },

    // An error occured. Receives the error message as second parameter
    // and if the error was due to the XMLHttpRequest the xhr object as third.
    onError(originalFile, message, xhr) {
      const media = find(this.files, { uid: originalFile.upload.uuid })
      if (media) {
        media.dropzone.error = {
          message,
          xhr
        }
      }
    },

    // handle event when user remove a file
    removeFile(file) {
      // check if the file is a new file
      if (file.originalFile) {
        this.$refs.dropzone.removeFile(file.originalFile)
      } else {
        const index = findIndex(this.files, { id: file.id })
        this.$emit('onFileRemove', this.files[index].id)
        this.deleteMediaOnServer(this.files[index].id)
        if (index > -1) {
          this.files.splice(index, 1)
        }
      }
    },

    // remove file that is not yet uploaded
    originalFileRemoved(originalFile) {
      const index = findIndex(this.files, { uid: originalFile.upload.uuid })
      this.$emit('onFileRemove', this.files[index].id)
      this.deleteMediaOnServer(this.files[index].id)
      if (index > -1) {
        this.files.splice(index, 1)
      }
    },

    async deleteMediaOnServer(mediaId) {
      if (this.autoBackendDelete && !String(mediaId).includes('-')) {
        await new Media({ id: mediaId }).delete()
      }
    }
  },
  created() {
    each(this.storedFiles, file => {
      this.files.push({
        uid: file.id,
        ...file
      })
    })
  },

  watch: {
    storedFiles(files) {
      each(files, file => {
        this.files.push({
          uid: file.id,
          ...file
        })
      })
    }
  }
}
</script>
<style></style>
