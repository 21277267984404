<template>
  <div>
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="subtitle-1 d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'venues' }"
          >Venues</router-link
        >
        <v-icon small class="mx-2"> {{ icons.arrow }} </v-icon>
        New
      </v-toolbar-title>
    </v-app-bar>
    <v-row>
      <v-col>
        <div class="my-6 mt-12 mx-12">
          <form-label required> Venue Photos </form-label>
          <media-uploader
            class="mb-6 mt-2"
            :loading.sync="venuePhotosUploading"
            :storedFiles="storedVenuePhotos"
            @onFileUploadSuccess="venuePhotoAdded"
            @onFileRemove="venuePhotoRemoved"
            :error="hasError"
          />
          <div class="v-messages theme--light error--text mb-3" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">{{ photosError }}</div>
            </div>
          </div>
          <v-form ref="form">
            <form-label required>
              Venue Name
            </form-label>
            <v-text-field
              flat
              required
              class="mt-2"
              v-model="venue.name"
              outlined
              placeholder="Type in venue name"
              :error-messages="errors.name"
            ></v-text-field>
            <v-row>
              <v-col cols="6">
                <form-label required> Venue Type </form-label>
                <v-select
                  :items="venueTypes"
                  item-text="name"
                  item-value="id"
                  v-model="venue.venue_type_id"
                  placeholder="Select venue type"
                  flat
                  class="mt-2"
                  required
                  outlined
                  :error-messages="errors.venue_type_id"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <form-label required> Price Range </form-label>
                <v-select
                  :items="price_ranges"
                  flat
                  placeholder="Select price range"
                  class="mt-2"
                  outlined
                  v-model="venue.price_range"
                  :error-messages="errors.price_range"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <form-label> Tags </form-label>
            <v-autocomplete
              :disabled="loading"
              :items="tags"
              v-model="venue.tags"
              chips
              label="Select tag/s"
              item-text="tag"
              item-value="id"
              multiple
              outlined
              class="mt-2"
              flat
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.tag }}
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.tag"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

            <form-label required>Address</form-label>
            <places-search
              background-color="grey lighten-3"
              :error-messages="errors.address"
              @selected="addressSelected"
            ></places-search>

            <form-label> Google Business Link </form-label>
            <v-text-field
              flat
              outlined
              required
              placeholder="Type in google business link"
              class="mt-2"
              v-model="venue.google_business_link"
              :error-messages="errors.google_business_link"
            ></v-text-field>

            <form-label required> Phone Number </form-label>
            <v-text-field
              flat
              outlined
              required
              placeholder="Type in contact number"
              class="mt-2"
              v-model="venue.phone_number"
              :error-messages="errors.phone_number"
            ></v-text-field>

            <form-label required> Staff Email </form-label>
            <v-text-field
              flat
              required
              outlined
              class="mt-2"
              placeholder="Type in staff email address"
              v-model="venue.staff_email"
              :error-messages="errors.staff_email"
            ></v-text-field>

            <form-label>Description </form-label>
            <v-textarea
              flat
              outlined
              required
              placeholder="Type in venue description"
              class="mt-2"
              v-model="venue.description"
              :error-messages="errors.description"
            ></v-textarea>
            <schedule @changed="timeUpdated" :errors="errors" />
          </v-form>
          <v-col cols="12">
            <div class="d-flex align-end">
              <v-btn
                :loading="saveLoading"
                class="ml-auto"
                rounded
                depressed
                large
                color="primary"
                @click="saveChanges"
              >
                Save Changes
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { findIndex, each } from 'lodash'
import MediaUploader from '@/components/dropzone/media-uploader'
import PlacesSearch from '@/components/places/places-search'
import VenueTag from '@/models/VenueTag'
import Schedule from '@/components/Venues/Schedule'

import Form from '@/utils/form'

export default {
  name: 'VenueDetails',
  components: {
    AppBarNavIcon,
    MediaUploader,
    PlacesSearch,
    Schedule
  },
  data() {
    return {
      icons: {
        arrow: mdiChevronRight
      },
      venuePhotosUploading: false,
      storedVenuePhotos: [],
      price_ranges: ['$', '$$', '$$$', '$$$$'],
      loading: false,
      saveLoading: false,
      deleteLoading: false,
      deleteDialog: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      errors: {},
      venue: new Form({
        venue_photos: [],
        tags: []
      }),
      deletebleMediaIds: [],
      tags: [],
      selectedTags: []
    }
  },

  computed: {
    ...mapState({
      venueTypes: state => state.venueType.list
    }),
    hasError() {
      if (this.errors.venue_photos && this.errors.venue_photos.length > 0) {
        return true
      }
      return false
    },
    photosError() {
      if (this.errors.venue_photos && this.errors.venue_photos.length > 0) {
        return this.errors.venue_photos[0]
      }
      return ''
    }
  },

  methods: {
    ...mapActions({
      getVenueTypes: 'venueType/getVenueTypes',
      saveVenue: 'venue/saveVenue'
    }),
    venuePhotoAdded(media) {
      this.venue.venue_photos.push(media.id)
    },
    venuePhotoRemoved(id) {
      const index = findIndex(this.venue.venue_photos, v => v === id)
      if (index > -1) {
        this.deletebleMediaIds.push(id)
        this.venue.venue_photos.splice(index, 1)
      }
    },
    addressSelected(place) {
      this.venue.address = ''
      this.venue.place_id = ''
      this.venue.address = place.description
      this.venue.place_id = place.place_id
    },
    async fetchVenueTypes() {
      this.loading = true
      await this.getVenueTypes({ page: 1 })
      this.loading = false
    },
    async saveChanges() {
      this.saveLoading = true
      const res = await this.saveVenue(this.venue)

      if (typeof res.errors === 'undefined') {
        this.errors = {}
        this.$router.replace({ name: 'venues' })
      } else {
        this.errors = res.errors
      }
      this.saveLoading = false
    },

    async getTags() {
      this.loading = true
      await VenueTag.page(1)
        .get()
        .then(d => {
          each(d.data, tag => {
            this.tags.push(tag)
          })
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    remove(item) {
      const index = findIndex(this.venue.tags, i => {
        return i === item.id
      })
      if (index >= 0) this.venue.tags.splice(index, 1)
    },
    timeUpdated(time) {
      this.venue.venue_hours = time
    }
  },

  created() {
    this.fetchVenueTypes()
    this.getTags()
  }
}
</script>
