<template>
  <div class="file-preview">
    <v-img
      :src="media.thumb_url"
      height="90px"
      width="90px"
      class="preview-img"
    />
    <v-btn icon class="preview-close" small>
      <v-icon class="white rounded-circle" @click="removeFile">{{
        icons.close
      }}</v-icon>
    </v-btn>
    <div class="preview-filename">{{ media.name }}</div>
    <div class="file-upload-progress" v-show="showProgress">
      <v-progress-circular
        :rotate="-90"
        :size="50"
        :value="progress"
        color="white"
      >
        {{ progress }}
      </v-progress-circular>
    </div>
    <div class="file-upload-error" v-show="showError">
      <v-tooltip top color="error">
        <template v-slot:activator="{ on, attrs }">
          <v-icon large color="error" v-on="on" v-bind="attrs">
            {{ icons.closeOutlined }}
          </v-icon>
        </template>
        <span>There's an error while uploading.</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mdiCloseCircle, mdiAlertCircleOutline } from '@mdi/js'

export default {
  props: {
    media: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      progress: 100,
      showProgress: false,
      showError: false,
      error: {
        message: '',
        xhr: {}
      },
      icons: {
        close: mdiCloseCircle,
        closeOutlined: mdiAlertCircleOutline
      }
    }
  },

  methods: {
    removeFile() {
      this.$emit('onRemove', this.media)
    },

    updateProgress() {
      if (this.media && this.media.dropzone) {
        this.progress = Math.round(this.media.dropzone.progress)
        if (this.progress !== 100) {
          this.showProgress = true
        } else {
          setTimeout(() => {
            this.showProgress = false
          }, 500)
        }
      }
    },

    updateError() {
      if (this.media && this.media.dropzone.error) {
        this.error = this.media.error
        this.showError = true
        this.showProgress = false
        this.progress = 100
      }
    }
  },

  created() {
    this.updateProgress()
  },

  watch: {
    'media.dropzone.progress'(val) {
      this.updateProgress()
    },
    'media.dropzone.error'(val) {
      this.updateError()
    }
  }
}
</script>

<style></style>
