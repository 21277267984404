<template>
  <v-autocomplete
    class="place-search"
    outlined
    flat
    v-model="place_id"
    :rules="rules"
    :items="uniqItems"
    :loading="loading"
    :search-input.sync="address"
    item-text="description"
    item-value="place_id"
    :placeholder="placeholder"
    hide-no-data
    clearable
    open-on-clear
    auto-select-first
    eager
    no-data-text="No Results"
    @keyup="search"
    @change="onChanged"
    :error-messages="errorMessages"
  />
</template>

<script>
import { debounce, uniqBy, each, find } from 'lodash'

export default {
  props: {
    value: {
      type: String,
      required: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Type in Venue address'
    },
    errorMessages: {
      type: [Array, String, Object],
      required: false
    },
    preFillItems: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      address: null,
      place_id: this.value,
      items: this.preFillItems,
      loading: false
    }
  },

  computed: {
    uniqItems() {
      return uniqBy(this.items, 'place_id')
    }
  },

  methods: {
    search: debounce(function() {
      if (!this.address) return
      this.fetchPlaces()
    }, 600),
    async fetchPlaces() {
      this.loading = true
      const { data } = await this.$api.get('/places/search', {
        params: { input: this.address }
      })
      if (data.data) {
        each(data.data, item => {
          this.items.unshift(item)
        })
      }
      this.loading = false
    },
    onChanged(placeId) {
      const place = find(this.items, { place_id: placeId })
      this.$emit('selected', place)
    }
  },

  watch: {
    value(newValue) {
      this.place_id = newValue
    },
    place_id(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style>
.place-search .v-select__slot .v-input__icon.v-input__icon--append {
  display: none;
}
</style>
