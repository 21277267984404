import Model from './Model'

export default class Media extends Model {
  resource() {
    return 'media'
  }

  get isImage() {
    return String(this.mime_type).includes('image')
  }
}
